import {useState, useEffect} from 'react';

import {MARKETO_BASE_URL} from 'src/routes/externalUrls';
import {isSSR} from 'src/utils/ssr';

type MarketoProps = {
  munchkinId: string;
  formId: number;
  formKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (form: any) => void;
};

type MarketoForm = {
  submit: () => unknown;
  getValues: () => Record<string, string>;
  setValues: (values: Record<string, string>) => void;
};

declare global {
  interface Window {
    MktoForms2?: {
      loadForm: (
        baseUrl: string,
        munchkinId: MarketoProps['munchkinId'],
        formKey: MarketoProps['formId'],
        callback: MarketoProps['callback'],
      ) => void;
      getForm: (formId: number) => MarketoForm | undefined;
      whenReady: (form: any) => void;
      $: (elem: any) => void;
    };
  }
}

const appendScript = (setScriptLoaded: (bool: boolean) => void): void => {
  if (window.MktoForms2) return setScriptLoaded(true);
  const script = document.createElement('script');
  script.src = `${MARKETO_BASE_URL}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applyCustomCallbacks = (form: any): void => {
  form.addHiddenFields({_mkt_trk: ''});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form.onSubmit((submittedForm: any) => {
    submittedForm.vals({_mkt_trk: ''});
  });
};

export const useMarketo = ({munchkinId, formId, formKey, callback}: MarketoProps): {isLoading: boolean} => {
  if (isSSR) return {isLoading: false};
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded && !formLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mergedCallback = (form: any) => {
        applyCustomCallbacks(form);
        callback(form);
        setFormLoaded(true);
      };
      window.MktoForms2?.loadForm(MARKETO_BASE_URL, munchkinId, formId, mergedCallback);
      window.MktoForms2?.whenReady((form: any) => {
        form.onValidate(() => {
          const values = form.vals();
          if (values.PostalCode && values.PostalCode.length !== 5) {
            form.submittable(false);
            form.submitable(false);
            const formEl = form.getFormElem()[0];
            const emailEl = formEl.querySelector("[name='PostalCode']");
            form.showErrorMessage('Enter a valid US postal code', window.MktoForms2?.$(emailEl));
          } else {
            form.submitable(true);
            form.submittable(true);
          }
        });
      });
    }
    appendScript(setScriptLoaded);
  }, [scriptLoaded, formLoaded, munchkinId, formId, formKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return {isLoading: !formLoaded};
};
