import React, {useState} from 'react';

import css from '@styled-system/css';
import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Box, LayoutGrid, Grid, Loading, SEOWithQuery, BulletBox} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {useMarketo} from 'src/hooks/useMarketoForm';
import * as paths from 'src/routes/paths';
import {typographyStyles} from 'src/styleguide/typographyStyles';
import {nominatePage, seo} from 'src/utils/copy';
import {getMarketoFormElementId, marketoFormIds, munchkinId} from 'src/utils/marketo';
import styled from 'styled-components';

const NOMINATE_ID = marketoFormIds.nominate;
const NOMINATE_FORM_ID = getMarketoFormElementId(NOMINATE_ID);

const {H2, H3, BodyLarge} = Typography;

const MarketoForm = styled.form`
  &&& {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
    grid-row-gap: 20px;
    align-items: center;

    @media (max-width: ${({theme}) => theme.breakpoints[2]}) {
      grid-template-columns: 1fr;
      padding: 0;
    }

    &[style] {
      width: 100% !important;
    }

    .mktoFormRow {
      width: 100%;

      &:nth-of-type(2) {
        margin-bottom: 70px;
      }

      @media (min-width: ${({theme}) => theme.breakpoints[3]}) {
        &:nth-of-type(1) {
          margin-bottom: 70px;
        }
      }
    }

    .mktoFieldWrap,
    .mktoFormCol {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .mktoLabel {
      display: none;
    }

    .mktoGutter,
    .mktoOffset,
    .mktoAsterix {
      display: none;
    }

    .mktoError {
      position: absolute;
    }

    .mktoErrorMsg {
      ${css(typographyStyles.P3)};
      color: ${({theme}) => theme.colors.cream};
      background: ${({theme}) => theme.colors.poppyRed};
      padding: 0.8em 0.6em;
      border: none;
      box-shadow: none;
      text-shadow: none;
    }

    .mktoErrorArrow {
      background-color: ${({theme}) => theme.colors.poppyRed};
      border-color: ${({theme}) => theme.colors.poppyRed};
      transform: translateX(4px) rotate(45deg);
    }

    input.mktoField {
      border-radius: 0px !important;
    }

    input[type='text'].mktoField,
    input[type='url'].mktoField,
    input[type='email'].mktoField,
    input[type='tel'].mktoField,
    input[type='number'].mktoField,
    input[type='date'].mktoField,
    textarea.mktoField,
    select.mktoField {
      ${css(typographyStyles.P2)};
      color: ${({theme}) => theme.colors.cream};
      min-height: 40px;
      width: 100%;
      border: 0;
      border-bottom: 1.5px solid ${({theme}) => theme.colors.lineGray};
      background: transparent;
      padding: 16px 0px;
      outline: none;

      &[style] {
        width: 100% !important;
      }

      &::placeholder {
        ${css(typographyStyles.P2)};
        color: ${({theme}) => theme.colors.lineGray};
      }

      &:focus {
        border-color: ${({theme}) => theme.colors.lightGold};
      }
    }

    input.mktoFormCol[type='hidden'] {
      display: none;

      + .mktoClear {
        display: none;
      }
    }

    .mktoButtonRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: center;

      @media (min-width: ${({theme}) => theme.breakpoints[2]}) {
        grid-column: 1 / span 2;
      }
    }

    .mktoButtonWrap {
      &[style] {
        margin: 0 !important;
      }

      @media (max-width: ${({theme}) => theme.breakpoints[2]}) {
        width: 100%;
      }
    }

    .mktoCheckboxList {
      display: flex;
      flex-direction: row;
      align-items: center;

      &[style] {
        width: 100% !important;
      }

      input.mktoField {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0px;
        border: 1.25px solid ${({theme}) => theme.colors.lightGold};
        box-sizing: border-box;
        height: 18px;
        width: 18px;
        flex-shrink: 0;

        &:checked::after {
          content: '';
          display: block;
          width: 4px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          margin: 0px 5px;
        }

        ~ label {
          color: ${({theme}) => theme.colors.cream};
          margin-left: 16px;
        }
      }

      label {
        ${css(typographyStyles.P2)};
        color: ${({theme}) => theme.colors.lineGray};
        margin-bottom: 0;
      }
    }

    &&& {
      select.mktoField {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        color: ${({theme}) => theme.colors.cream};

        ~ .mktoInstruction {
          /* CSS arrow for select */
          display: block;
          position: relative;
          :after {
            content: '';
            position: absolute;
            right: 10px;
            bottom: calc(100% + 24px);
            border-style: solid;
            border-width: 1px 1px 0 0;
            border-color: white;
            display: inline-block;
            height: 6px;
            width: 6px;
            transform: rotate(135deg);
            vertical-align: top;
          }
        }
      }

      button.mktoButton {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: ${({theme}) => theme.colors.darkGold};
        border: 1px solid ${({theme}) => theme.colors.darkGold};
        ${css(typographyStyles.P0)};
        text-align: center;
        color: ${({theme}) => theme.colors.white};
        padding: ${({theme}) => theme.space.S}px ${({theme}) => theme.space.M}px;
        min-width: 218px;
        min-height: 38px;
        cursor: pointer;
        transition: background-color 200ms ease;

        &:active,
        &:disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: transparent;
          color: ${({theme}) => theme.colors.darkGold};
        }

        @media (max-width: ${({theme}) => theme.breakpoints[2]}) {
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
`;
const ListItem = styled.li`
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const Description = styled(Grid).attrs({
  gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
  gridColumnGap: 'XXL',
})``;

const NominatePage: React.FC = () => {
  const [formKey, setFormKey] = useState(Date.now().toString());
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onFormSubmit = () => {
    setFormKey(Date.now().toString());
    setShowConfirmation(true);
    Events.didSubmitForm({page: 'Nominations', form: 'External Nominations'});
    return false;
  };

  const {isLoading} = useMarketo({
    munchkinId,
    formId: NOMINATE_ID,
    formKey: formKey,
    callback: form => {
      form.onSuccess(onFormSubmit);
    },
  });

  return (
    <Box backgroundColor="darkGreen" color="cream" pb={['108px', null, null, null, '136px', '150px']}>
      <Box backgroundColor="green" textAlign="center" maxWidth="100%" p={['M', null, null, 'XL']}>
        <LayoutGrid width={['100%', null, null, '90%']} justifyItems="center">
          <Box gridColumn="1/end" width="650px" maxWidth="100%">
            <H2 mb="SS" fontWeight="800">
              {nominatePage.heading}
            </H2>
            <BodyLarge maxWidth="100%" mx="auto">
              {nominatePage.subheading}
            </BodyLarge>
          </Box>
        </LayoutGrid>
      </Box>
      <LayoutGrid width={['100%', null, null, '90%']} justifyItems="center" pt="XL">
        <BulletBox
          gridColumn="1/end"
          mb="XL"
          heading={nominatePage.requirementsLabel}
          description={nominatePage.requirementsDescription}
          bullets={nominatePage.requirements}
          footer={
            <Anchor variant="largeText" href={paths.MEMBERSHIP_CRITERIA}>
              {nominatePage.membershipCriteriaLink}
            </Anchor>
          }
          keyAsBulletLabel
        />
        <Box justifySelf="center" gridColumn="1/end" width="100%">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <H3 pb="S" mb="S" borderBottom="1px solid" borderColor="lineGray">
                {nominatePage.formTitle}
              </H3>
              <BodyLarge mb="L">{nominatePage.formSubtitle}</BodyLarge>
            </>
          )}
          <MarketoForm key={formKey} id={NOMINATE_FORM_ID} />
        </Box>
      </LayoutGrid>
      <SEOWithQuery title={seo.nominateTitle} />
    </Box>
  );
};

export default NominatePage;
