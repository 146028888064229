export const munchkinId = '702-CII-507';
const APPLY_FORM_QA_ID = 2079;
const APPLY_FORM_PROD_ID = 1301;
const UK_APPLY_FORM_ID = 2302;

const applyFormId =
  process.env.NODE_ENV === 'development' || process.env.GATSBY_ACTIVE_ENV === 'staging'
    ? APPLY_FORM_QA_ID
    : APPLY_FORM_PROD_ID;

export const marketoFormIds = {
  apply: applyFormId,
  subscribe: 1321,
  nominate: 1290,
};

export const getMarketoFormElementId = (id: number): string => `mktoForm_${id}`;
